import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ForgotPasswordForm,
  ForgotPasswordFormValue,
} from '@stur/components/common/forgot-password-form';
import { PageContent } from '@stur/components/layout/page-content';
import { PageTitle } from '@stur/components/layout/page-title';
import { ActionStatusSelectors } from '@stur/store/action-status/action-status-selectors';
import { AuthActions } from '@stur/store/auth/auth-reducer';

export const ForgotPasswordPage: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(ActionStatusSelectors.isActionPending(AuthActions.resetPassword));

  const handleSubmit = (values: ForgotPasswordFormValue): void => {
    const { email } = values;
    dispatch(AuthActions.resetPassword({ email }));
  };

  return (
    <>
      <PageTitle header="Forgot Password" />
      <PageContent>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="cell medium-6 large-4">
              <ForgotPasswordForm isLoading={isLoading} onSubmit={handleSubmit} />
            </div>
            <div className="cell medium-6 large-8">
              <img src="https://picsum.photos/800/800" alt="About Stur" className="img-fluid" />
            </div>
          </div>
        </div>
      </PageContent>
    </>
  );
};
