import { Form, Formik } from 'formik';
import React, { VFC } from 'react';
import * as Yup from 'yup';

import { ActivityIndicator } from '@stur/components/core/activity-indicator';
import { Button, LinkButton } from '@stur/components/core/button';
import { FormError } from '@stur/components/core/form-error';
import { FormField } from '@stur/components/core/form-field';
import { RoutingUtils } from '@stur/utils/routing-utils';

import styles from './forgot-password-form.module.scss';

export interface ForgotPasswordFormProps {
  isLoading: boolean;
  onSubmit: (formValue: ForgotPasswordFormValue) => void;
}

export interface ForgotPasswordFormValue {
  email: string;
}

const initialValues: ForgotPasswordFormValue = {
  email: '',
};

const validationSchema: Yup.SchemaOf<ForgotPasswordFormValue> = Yup.object({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

export const ForgotPasswordForm: VFC<ForgotPasswordFormProps> = (props) => {
  const { isLoading, onSubmit } = props;

  const handleSubmit = (values: ForgotPasswordFormValue): void => onSubmit(values);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="forgot-password-form" noValidate>
        <label>
          <span>Email Address</span>
          <FormField<ForgotPasswordFormValue>
            type="email"
            name="email"
            autoComplete="email"
            placeholder="Email"
          />
        </label>
        <FormError<ForgotPasswordFormValue> name="email" />

        <div className={styles.formActions}>
          <LinkButton color="secondary-light" size="small" to={RoutingUtils.routes.logIn()}>
            Cancel
          </LinkButton>

          <Button type="submit" color="primary" className="expanded" disabled={isLoading}>
            <ActivityIndicator isLoading={isLoading}>Submit</ActivityIndicator>
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
